:root {
  --color-primary: #F9F5EB;
  --color-secondary: #E4DCCF;
  --color-tertiary: #EA5455;
  --color-accent: #EA5455;
  --color-background: #002B5B;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  color: var(--color-primary)
}

h1 {
  text-align: center;
  margin: 0;
}

label {
  margin-right: 1rem;
}

select {
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: none;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

button {
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  border: none;
  background-color: var(--color-primary);
  cursor: pointer;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  /* Adjust the width as needed */
  grid-gap: 1rem;
  margin: 2rem;
}

.card {
  position: relative;
  width: 100%;
  height: 30vh;
  /* Adjust the height as needed */
  background-color: #eee;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: transform 0.5s ease;
  transform-style: preserve-3d;
}


img {
  max-width: 100%;
  max-height: 100%;
}

.card-back,
.card-front {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: bold;
  border-radius: 0.25rem;
  color: var(--color-background);
}

.card-back {
  background-color: var(--color-primary);
}

.card-front {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
}

.card-front-text {
  margin-bottom: 1rem;
}

.card-front-image {
  width: 100%;
  height: 75%;
  max-height: calc(100% - 2rem);
  object-fit: cover;
  border-radius: 0.25rem;
}

.card.flipped {
  transform: rotateY(180deg);
  background-color: var(--color-primary);
}

.card.matched {
  background-color: #aaffaa;
  cursor: default;
}

.card.wrong {
  visibility: hidden;
  cursor: default;
}

.reset-button {
  margin-top: 1rem;
}

.typingCard {
  height: 5rem;
}

.typingInput {
  height: 5rem;
  text-align: center;
  border-radius: 0.25rem;
  border: none;
}

.typingButton {
  border-radius: 0.25rem;
}

.typingcard {
  position: relative;
  width: 50%;
  height: 30vh;
  display: flex;
  margin: 0 auto;
  border-radius: 0.25rem;
  transition: transform 0.5s ease;
  transform-style: preserve-3d;
}



.typingcard-front {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  flex-direction: column;
  text-align: center;
  border-radius: 0.25rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
}

.typingcard-front-image {
  width: 50;
  height: 75%;
  max-height: calc(100% - 2rem);
  border-radius: 0.25rem;
}

.typingcorrect{
  color: green
}

.typingfalse{
  color: red;
}

.typinghidden{
  color: var(--color-background);
}

.typingcheckbox{
  cursor:pointer;
}