.RandomInput {
    width: 100px;
    height: 40px;
    font-size: 20px;
    margin: 10px 0;
}

.btn {
    width: 150px;
    height: 50px;
    font-size: 20px;
    background-color: #4CAF50; /* Green */
    color: white;
    border: none;
    cursor: pointer;
    margin-top: 20px;
}

.btn:hover {
    background-color: #45a049;
}