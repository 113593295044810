* {
  box-sizing: border-box;
  font-family: "Comic Sans MS", "Comic Sans";
}

:root {
  --color-primary: #F9F5EB;
  --color-secondary: #E4DCCF;
  --color-tertiary: #EA5455;
  --color-accent: #EA5455;
  --color-background: #002B5B;
}

body {
  background: var(--color-background);
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  text-align: center;
}

.links {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.671);
}

.links li {
  display: inline-block;
  margin: 0 10px;
  padding: 5px;
}

.links a {
  margin-top: 20px;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 1.25rem;
  transition: transform 0.3s, box-shadow 0.3s, color 0.3s;
}

.links a:hover,
.links a:active,
.links a:focus {
  color: var(--color-tertiary);
  transform: scale(1.1);
  cursor: pointer;
  font-size: 1.25 rem;
}

.links a:hover{
  color: var(--color-secondary);
}

ul {
  padding: 0;
  margin: 0;
}

.pageHead {
  position: relative;
  height: 5%;
}

#genre-select {
  padding: 10px;
  margin-left: 10px;
  background-color: var(--color-primary);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

#genre-select:hover,
#genre-select:focus {
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2), 0 4px 8px rgba(0, 0, 0, 0.2);
}

.gameBody {
  color: var(--font-color);
  width: 100%;
  height: 95%;
  background: yellow;
  position: absolute;
  text-align: center;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}

.settingdiv {
  width: 25%;
  height: 100%;
  background-color: var(--color-secondary);
}

.cardContainer {
  width: 75%;
  display: flex;
  flex-wrap: wrap;
  background-color: red;
  justify-content: space-evenly;
  height: 100%;
}

.card {
  width: 33%;
  margin: 10px;
  padding: 10px;
  background-color: green;
}

.flashcard {
  height: 500px;
  border-radius: 0.5rem;
}

.RandomInput {
  border-radius: 0.5rem;
  width: 5rem;
  text-align: center;
  font-family: "Comic Sans MS";
}