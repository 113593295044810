:root {
  --color-primary: #F9F5EB;
  --color-secondary: #E4DCCF;
  --color-tertiary: #EA5455;
  --color-accent: #EA5455;
  --color-background: #002B5B;
}

.container {
  text-align: center;
}

.game-component {
  margin-top: 20px;
  padding: 20px;
}

.links {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.671);
}

.links li {
  display: inline-block;
  margin: 0 10px;
  padding: 5px;
}

.links a {
  margin-top: 20px;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 1.25rem;
  transition: transform 0.3s, box-shadow 0.3s, color 0.3s;
}

.links a:hover,
.links a:active,
.links a:focus {
  color: var(--color-tertiary);
  transform: scale(1.1);
  cursor: pointer;
  font-size: 1.25 rem;
}

.links a:hover {
  color: var(--color-secondary);
}

ul {
  padding: 0;
  margin: 0;
}


.li-copy {
  list-style: none;
  margin: 0 10px; 
  padding: 5px; 
  text-align: center;
  color: black;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 1.25rem;
  transition: transform 0.3s, box-shadow 0.3s, color 0.3s;
  display: inline-block;
}

.li-copy:hover,
.li-copy:active,
.li-copy:focus {
  color: var(--color-tertiary);
  transform: scale(1.1);
  cursor: pointer;
}

.li-copy:hover {
  color: var(--color-secondary);
}


.li-copy.selected {
  color: var(--color-tertiary); 
  font-weight: bold;
}
